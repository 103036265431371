<template>
  <div class="spinner-grow modal-loader" v-show="modalLoader">
    <span class="sr-only"></span>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  data() {
    return {
      modalLoader: false,
    };
  },
};
</script>
<style>
.modal-loader {
  width: 3rem;
  height: 3rem;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
}
.spinner-grow {
    background-color: var(--primary_color) !important;
}
</style>