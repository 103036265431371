<template>
  <a href="javascript:void(0)" @click="sorting(sortBy)" class="color-black" style="display:flex;">
    <div class="sortinglable" >
      <span v-html="lable">{{ lable }}</span>
    </div>
    <div class="sortingicon" style="margin-left: 11px;"> 
      <template v-if="listConfig.sortBy == sortBy && listConfig.order == 'DESC'">
        <img
          alt="icon"
          :src="require('@/assets/sorting-icons/down-arrow.svg')"
          class="down-arrow"
          style="margin-left: 2px"
        />
      </template>
      <template
        v-else-if="listConfig.sortBy == sortBy && listConfig.order == 'ASC'"
      >
        <img
          alt="icon"
          :src="require('@/assets/sorting-icons/up-arrow.svg')"
          class="up-arrow"
        />
      </template>
      <template v-else>
        <img
          alt="icon"
          :src="require('@/assets/sorting-icons/up-arrow.svg')" 
          class="up-arrow"
        />
        <img
          alt="icon"
          :src="require('@/assets/sorting-icons/down-arrow.svg')"
          class="down-arrow"
        />
      </template>
    </div>
  </a>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    listConfig: {
      //   type: Array,
      required: true
    },
    sortBy: {
      type: String,
      required: true
    },
    lable: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //   selected_page:1
    };
  },

  methods: {
    sorting(column) {
      console.log(column);
      if (this.listConfig.sortBy != this.sortBy) {
        this.listConfig.sortBy = column;
        this.listConfig.order = "DESC";
      } else {
        this.listConfig.sortBy = column;
        if (this.listConfig.order == "DESC") {
          this.listConfig.order = "ASC";
        } else if (this.listConfig.order == "ASC") {
          this.listConfig.order = "";
          this.listConfig.sortBy = "";
        } else {
          this.listConfig.order = "DESC";
        }
      }

      this.$emit("sorting", this.listConfig);
    }
  }
};
</script>

