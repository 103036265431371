<template>
      <div class="modal box_up fade" id="dataset" tabindex="-1" aria-labelledby="downloadLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-header download-btn-right">
              <h5 class="modal-title" id="downloadLabel">
                <h2 class="heading_2 font_size-20">Data Set</h2>
              </h5>
              <div class="down_cross">
                <a href="javascript:" class="ml_12 xl_download" v-if="!isEmptyDataSet" @click="exportDataset()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
              <g data-name="Group 12689">
                <path data-name="Path 114" d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4" transform="translate(-3 -3)" style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
              </g>
            </svg>
            Download
          </a>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetDataSet"></button>
              </div>              
            </div>
            <Spinner v-show="!this.dataSet" ref="Spinner"></Spinner>
            <div class="modal-body details_eval">
              <div class="row justify-content-center">
                <div class="col-xs-12 col-md-12 main-timeline cust_scroll">
                  <div v-if="isEmptyDataSet" class="row">
                    <div class="col-xs-12 col-md-12">
                      <h2 class="text-center no_data mb-5">There is no dataset available</h2>
                    </div>
                  </div>
                  <div class="table table-responsive for_img" v-else>
                    <table class="table historyTable" width="100%" align="center">
                      <tr>
                        <th style="width:10%">ID</th>
                        <th style="width:40%">QUESTION</th>
                        <th style="width:50%">ANSWER</th>
                      </tr>
                      <tr v-for="(dataValue, dataKey) in dataSet" :key="dataKey">
                        <td style="width:10%" v-html="dataValue.id"></td>
                        <td style="width:40%" v-if="dataValue.question != undefined" v-html="dataValue.question.replace(/(<br ?\/?>|<hr ?\/?>|<label ?\/?>|<\/?span[^>]*>|<\/?div[^>]*>)+/gi,'')"></td>
                        <td style="width:40%" v-else v-html="dataValue.question"></td>
                        <td style="width:50%" v-if="dataValue.answer != undefined" v-html="dataValue.answer ? dataValue.answer.replace(/(<br ?\/?>|<hr ?\/?>|<label ?\/?>|<\/?span[^>]*>|<\/?div[^>]*>)+/gi,'') : 'Not Answered'" ></td>
                        <td style="width:50%" v-else v-html="dataValue.answer ? dataValue.answer : 'Not Answered'" ></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue"
import exportFromJSON from "export-from-json";

export default {
    name: "DatasetModal",
    props: ["dataSet","isEmptyDataSet","downloadMessage","dashboard"],
    components: {
      Spinner
    },
    methods:{
      exportDataset() {
        var filterExport = [];
        var _this = this;
        var data;
        if (_this.dataSet) {
          Object.keys(_this.dataSet).forEach(key => {
            if(_this.dataSet[key].question != undefined){
              filterExport.push({
                "Id": _this.dataSet[key].id,
                "Question": _this.dataSet[key].question.replace(/<[^>]*>?/gm, ''),
                "Answer": _this.dataSet[key].answer ? _this.dataSet[key].answer : "Not Answered",
              });
            }
          });
          data = filterExport;
        } 
        const fileName = "report-dataset";
        const exportType = exportFromJSON.types.csv;

        //Audit log for download
        var requestData =  {'section': "Reports In Review",'dashboard':this.dashboard,'datasetMsg':this.downloadMessage};
        // var requestData = {'section': this.downloadMessage,'dashboard':this.dashboard};
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.$parent.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        });

        if (data) exportFromJSON({ data, fileName, exportType });
      },
      resetDataSet(){
        // this.$emit('dataSet', []);
        this.$parent.dataSet = [];
      },
    }
}
</script>
